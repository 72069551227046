import { ComponentProps } from 'react';

import isRichTextContentEmpty from './checkEmptyRichText';
import RichText, { TRichTextContent } from '../RichText';

export const toRichText = (
  value?: TRichTextContent,
  extraProps?: Partial<ComponentProps<typeof RichText>>,
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
) => {
  return value && !isRichTextContentEmpty(value) ? (
    <RichText content={value} {...extraProps} />
  ) : undefined;
};
